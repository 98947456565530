/*
Project: Aplikace pro oziveni fotek
Author: Adrian Bobola
Email: xbobol00@stud.fit.vutbr.cz
University: FIT VUT Brno
Created on: 2024-05-09
*/

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.custom-file-upload {
    border-radius: 25px;
    background: linear-gradient(
            180deg,
            rgba(162, 89, 234, 0.39) 0%,
            rgba(205, 156, 255, 0) 100%
    );
    color: #cbcbcb;
    font-size: 18px;
    font-family: "Red Rose", sans-serif;
    padding: 15px 40px;
    cursor: pointer;
    margin-top: 10%;
    margin-left: 32%;
}

.custom-file-upload-upload-page {
    border-radius: 25px;
    background: linear-gradient(
            180deg,
            rgba(162, 89, 234, 0.39) 0%,
            rgba(205, 156, 255, 0) 100%
    );
    color: #cbcbcb;
    font-size: 18px;
    font-family: "Red Rose", sans-serif;
    padding: 15px 40px;
    cursor: pointer;
    margin-top: 10%;
}

#file-upload {
    display: none;
}

.custom-file-upload-upload-page:hover {
    background-color: #0056b3;
}

.button-small {
    border-radius: 25px;
    background: linear-gradient(
            180deg,
            rgba(162, 89, 234, 0.39) 0%,
            rgba(205, 156, 255, 0) 100%
    );
    color: #cbcbcb;
    font-size: 15px;
    font-family: "Red Rose", sans-serif;
    padding: 15px 40px;
    cursor: pointer;
    text-decoration: none;
}

.button-small:hover {
    background-color: #0056b3;
}

.custom-file-upload:hover {
    background-color: #0056b3;
    text-decoration: none;
}

.rectangle {
    color: white;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.5);
    width: 70vw;
    max-height: 80vh;
    overflow-y: auto;
}

.rectangle-manual-page {
    color: white;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.5);
    width: 70%;
    height: 80vh;
    overflow-y: auto;
    font-family: "Red Rose", sans-serif;
    white-space: pre-line;
}

.error-rectangle {
    color: white;
    position: absolute;
    top: 56%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.5);
    width: 70%;
    height: 40vh;
    overflow-y: auto;
}

.rectangle-file-uploading {
    color: white;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.5);
    width: 70%;
    height: 80vh;
    overflow-y: auto;
}

.main-container {
    background: linear-gradient(
            0deg,
            rgba(100, 0, 40, 0.6) 40%,
            rgba(100, 0, 100, 0.2) 100%
    ) 0 0 / 100% 100% no-repeat,
    linear-gradient(
            180deg,
            #191944 30%,
            rgba(77, 28, 129, 0.04) 99.99%,
            rgba(149, 121, 177, 0) 90%,
            #714d95 100%
    ) 0 0 / auto auto no-repeat;
    padding: 60px 20px;
    text-align: center;
    height: calc(100vh - 120px);
}

.content-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.video-player {
    flex-basis: 5%;
    flex-grow: 1;
}

.nav {
    display: flex;
    align-items: center;
}

.nav > :first-child {
    margin-right: auto;
    margin-left: 2%;
}


.logo {
    background: linear-gradient(
            180deg,
            #fff 0%,
            rgba(178, 178, 178, 0) 100%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 2rem;
    font-family: "Outfit", sans-serif;
    margin-left: 40px;
    text-decoration: none;
}

.navbar-text {
    color: #fff;
    font-size: 1.25rem;
    font-family: "Outfit", sans-serif;
    margin-right: 40px;
}

@media (min-width: 768px) and (max-width: 1024px) {
    .navbar-text {
        font-size: 1rem;
    }
}

@media (min-width: 1920px) {
    .navbar-text {
        font-size: 1.5rem;
    }
}

@media (min-width: 3840px) {
    .navbar-text {
        font-size: 2rem;
    }
}

.title {
    color: #fff;
    font-size: 4vh;
    font-family: "Red Rose", sans-serif;
    margin-bottom: 40px;
    margin-top: 120px;
    margin-left: 30%;
}

.description {
    color: #cbcbcb;
    font-size: 3vh;
    font-family: "Red Rose", sans-serif;
    margin-left: 30%;
}

.terms {
    color: #cbcbcb;
    font-size: 16px;
    font-family: "Red Rose", sans-serif;
    margin-bottom: 40px;
    margin-left: 30%;
}

.terms-upload-page {
    color: #cbcbcb;
    font-size: 16px;
    font-family: "Red Rose", sans-serif;
    margin-bottom: 40px;
}

.error {
    color: #cbcbcb;
    font-size: 24px;
    font-family: "Red Rose", sans-serif;
}

.image {
    width: 100%;
    max-width: 100%;
    height: auto;
    display: block;
    margin-top: 10%;
    margin-right: 20%;
}

.text-content {
    flex: 1;
    text-align: left;
    margin-right: 20px;
}

.footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    color: #fff;
    padding: 10px;
    text-align: center;
}


@media (max-width: 1000px) {
    .main-container {
    background: linear-gradient(
            0deg,
            rgba(100, 0, 40, 0.6) 40%,
            rgba(100, 0, 100, 0.2) 100%
    ),
    linear-gradient(
            180deg,
            #191944 30%,
            rgba(77, 28, 129, 0.04) 99.99%,
            rgba(149, 121, 177, 0) 90%,
            #714d95 100%
    );
    width: 1000px;
    height:600px;
    background-repeat:no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
    .text-content, .video-player {
        flex: 1 1 50%;
        width: 20%;
        padding: 0px;
    }

    .title {
        font-size: 2rem;
    }

    .description {
        font-size: 1rem;
    }

    .rectangle-manual-page {
        color: white;
        position: fixed;
        top: 80vh;
        left: 70vw;
        width: 80vw;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.5);
        overflow-y: auto;
        font-family: "Red Rose", sans-serif;
        white-space: pre-line;
    }

    .rectangle-file-uploading {
        color: white;
        position: absolute;
        top: 80vh;
        left: 70vw;
        width: 80vw;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.5);
        overflow-y: auto;
    }

    .rectangle {
        color: white;
        position: absolute;
        top: 80vh;
        left: 70vw;
        width: 80vw;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.5);
        max-height: 80vh;
        overflow-y: auto;
    }
}

.about-container {
    text-align: left;
    color: #cbcbcb;
    font-size: 20px;
    font-family: "Red Rose", sans-serif;
    margin: 5% 10%;
    white-space: pre-line;
    line-height: 1.6;
}

.webcam-display {
    width: 75vh;
    height: 50vh;
    object-fit: cover;
}